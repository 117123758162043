import React from 'react';
// import RealTimeChart from './RealTimeChart';
import RealTimeChart from './RealTimeChart/RealTimeChart';
import './App.css';

const App = () => (
  <div>
    <RealTimeChart />
  </div>
);

export default App;

import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import io from "socket.io-client";

const socket = io(`${process.env.REACT_APP_BACKEND_API}`);
const symbols = ["NIFTY", "BANKNIFTY", "FINNIFTY", "MIDCPNIFTY", "NIFTYNXT50"];

const ApexChartComponent = () => {
  const [seriesArray, setSeriesArray] = useState(
    symbols.map((symbol) => ({ name: symbol, data: [] }))
  );
  const [selectedSymbols, setSelectedSymbols] = useState(["NIFTY"]);
  const previousSymbols = useRef([...selectedSymbols]);

  const fetchInitialData = async (symbol, index) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/api/data/${symbol}`
      );
      const initialData = response.data.map((data) => ({
        x: new Date(data.time).getTime(),
        y: data.straddlePrice,
      }));

      setSeriesArray((prevSeries) =>
        prevSeries.map((series, idx) => {
          if (idx === index) {
            return { ...series, data: initialData };
          }
          return series;
        })
      );
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  const getSymbolsData = useCallback(() => {
    selectedSymbols.forEach((symbol, index) => {
      fetchInitialData(symbol, index);
    });

    const handlers = selectedSymbols.map((symbol, index) => {
      const eventName = `data-${symbol}`;

      const handler = (newData) => {
        const newDataPoint = {
          x: new Date(newData.time).getTime(),
          y: newData.straddlePrice,
        };

        setSeriesArray((prevSeries) =>
          prevSeries.map((series, idx) => {
            if (idx === index) {
              return { ...series, data: [...series.data, newDataPoint] };
            }
            return series;
          })
        );
      };

      socket.on(eventName, handler);
      return { eventName, handler };
    });

    return () => {
      handlers.forEach(({ eventName, handler }) => {
        socket.off(eventName, handler);
      });
    };
  }, [selectedSymbols]);

  useEffect(() => {
    getSymbolsData();
  }, [getSymbolsData]); // Run only once on mount

  useEffect(() => {
    selectedSymbols.forEach((symbol, index) => {
      if (previousSymbols.current[index] !== symbol) {
        fetchInitialData(symbol, index);

        const oldEventName = `data-${previousSymbols.current[index]}`;
        const newEventName = `data-${symbol}`;

        const handler = (newData) => {
          const newDataPoint = {
            x: new Date(newData.time).getTime(),
            y: newData.straddlePrice,
          };

          setSeriesArray((prevSeries) =>
            prevSeries.map((series, idx) => {
              if (idx === index) {
                return { ...series, data: [...series.data, newDataPoint] };
              }
              return series;
            })
          );
        };

        socket.off(oldEventName);
        socket.on(newEventName, handler);
      }
    });

    previousSymbols.current = [...selectedSymbols];
  }, [selectedSymbols]); // React to changes in symbols

  const handleSymbolChange = (index, symbol) => {
    const newSymbols = [...selectedSymbols];
    newSymbols[index] = symbol;
    setSelectedSymbols(newSymbols);
  };

  const handleChartNumberChange = (event) => {
    const newNumberOfCharts = Number(event.target.value);
    const newSelectedSymbols = Array(newNumberOfCharts)
      .fill("NIFTY")
      .map((symbol, index) => selectedSymbols[index] || symbol);
    setSelectedSymbols(newSelectedSymbols);
  };

  // Calculate dynamic step size for Y-axis
  const getYAxisStepSize = (series) => {
    if (series.length === 0 || series[0].data.length === 0) return 1;
    const allYValues = series.flatMap((s) => s.data.map((d) => d.y));
    const minY = Math.min(...allYValues);
    const maxY = Math.max(...allYValues);
    const range = maxY - minY;
    return Math.ceil(range / 10); // Adjust the divisor for more or fewer steps
  };

  const getChartOptions = (series) => ({
    chart: {
      type: "line",
      height: 350,
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000,
        },
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      tickAmount: 6,
      labels: {
        formatter: function (value) {
          return new Date(value).toLocaleTimeString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
          });
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toFixed(2);
        },
      },
      stepSize: getYAxisStepSize([series]),
    },
    tooltip: {
      x: {
        format: "HH:mm:ss",
      },
    },
  });

  return (
    <>
      <div>
        <div className="text-center row justify-content-center w-25 outline-none mx-auto my-4">
          <select
            value={selectedSymbols.length}
            className="form-select"
            onChange={handleChartNumberChange}
          >
            {[1, 2, 3, 4, 5].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
        <div className="row justify-content-center align-items-center chart_boxes position-relative">
          {selectedSymbols.map((symbol, index) => (
            <div key={index}>
              <select
                value={symbol}
                onChange={(e) => handleSymbolChange(index, e.target.value)}
                className="mb-5 form-select w-auto outline-none"
              >
                {symbols.map((symbol) => (
                  <option key={symbol} value={symbol}>
                    {symbol}
                  </option>
                ))}
              </select>
              <div className="row w-100">
                <div className="w-75">
                  <h5 className="text-center">ATM Premiums</h5>
                  <ReactApexChart
                    options={getChartOptions(seriesArray[index])}
                    series={seriesArray.filter((_, idx) => idx === index)}
                    type="line"
                    height={350}
                  />
                </div>
                <div className="table-column w-25">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Strike</th>
                        <th>Straddle</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>23150</td>
                        <td>252.00</td>
                      </tr>
                      <tr>
                        <td>23150</td>
                        <td>252.00</td>
                      </tr>
                      <tr className="negative">
                        <td>23150</td>
                        <td>252.00</td>
                      </tr>
                      <tr>
                        <td>23150</td>
                        <td>252.00</td>
                      </tr>
                      <tr>
                        <td>23150</td>
                        <td>252.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ApexChartComponent;
